import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import { Box, Flex, Icon } from '@qga/roo-ui/components';
import urlFor from '../../../../../libs/shared/sanity/image/urlFor';
import Image from '@/shared/components/Image';
import Link from '../Link';
import dataLayer from '@/v2/utils/dataLayer';
import { useGA4Events } from '@/v2/hooks/featureFlags/ga4Events/useGA4Events';

const CoverLink = styled(Link.Text)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &::after {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }
`;

const ShadowedText = styled(Box)`
  color: white;
  text-shadow: 0px 0px 14px rgba(0, 0, 0, 0.6);
`;

const Cover = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${({ comingSoon }) => {
    if (comingSoon) {
      return 'rgba(0, 0, 0, 0.75)';
    }

    return 'linear-gradient(rgba(0,0,0,0) 20%, rgba(0,0,0,0.75) 100%)';
  }};
`;

const Destination = ({
  name,
  title,
  image,
  count,
  comingSoon,
  heading,
  index,
}) => {
  const shouldUseNewEvents = useGA4Events();

  const renderTitle = () => (
    <Box px="4">
      <ShadowedText fontSize="xl" fontWeight="bold">
        {title}
      </ShadowedText>
    </Box>
  );

  return (
    <Flex
      data-testid="DESTINATION"
      height="200px"
      position="relative"
      justifyContent="center"
      alignItems="center"
      onClick={() => {
        if (shouldUseNewEvents) {
          dataLayer.tileClickEvent({
            groupName: heading,
            itemName: `${title} Holidays`,
            itemText: count ? `VIEW ${count} HOLIDAYS` : 'VIEW HOLIDAYS',
            index,
            url: `/${name}`,
          });
        }
      }}
    >
      <Box position="absolute" top="0" right="0" bottom="0" left="0">
        <Image
          src={urlFor(image).height(200).url()}
          alt={title}
          width="100%"
          height="100%"
          fit="cover"
        />
      </Box>
      <Cover
        position="absolute"
        top="0"
        right="0"
        bottom="0"
        left="0"
        comingSoon={comingSoon}
      />
      {comingSoon ? (
        <Box position="absolute" bottom="0" right="0" left="0">
          {renderTitle()}
          <Box p="4" pt="0">
            <ShadowedText fontSize="sm">Coming soon</ShadowedText>
          </Box>
        </Box>
      ) : (
        <CoverLink data-testid="LINK" href={`/${name}`}>
          <Box position="absolute" bottom="0" right="0" left="0" pb="4">
            {renderTitle()}
          </Box>
        </CoverLink>
      )}
    </Flex>
  );
};

Destination.defaultProps = {
  comingSoon: false,
  count: undefined,
};

const SanityImageResource = {};

Destination.propTypes = {
  heading: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  count: PropTypes.number,
  comingSoon: PropTypes.bool,
  image: PropTypes.shape(SanityImageResource).isRequired,
};

export default Destination;
