import PropTypes from 'prop-types';
import React from 'react';
import { Box, Container } from '@qga/roo-ui/components';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';

const StyledBox = styled(Box)`
  border-bottom-left-radius: ${themeGet('radii.default')};
  border-bottom-right-radius: ${themeGet('radii.default')};
`;

const Root = ({ children, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Box height={[null, null, '480px']} position="relative" {...props}>
    {children}
  </Box>
);

Root.propTypes = {
  children: PropTypes.node.isRequired,
};

const Background = ({ children, ...props }) => (
  <Box
    position={['relative', null, 'absolute']}
    height={['190px', null, '100%']}
    left="0"
    right="0"
    top="0"
    bottom="0"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    {children}
  </Box>
);

Background.propTypes = {
  children: PropTypes.node.isRequired,
};

const Content = ({ children, gutter, ...props }) => (
  <Box bg="white">
    <Container gutter={gutter}>
      <StyledBox
        position="relative"
        bg="white"
        width={[null, null, '500px']}
        py={['4', null, '8']}
        px={['2', null, '10']}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {children}
      </StyledBox>
    </Container>
  </Box>
);

Content.defaultProps = {
  gutter: 'default',
};

Content.propTypes = {
  children: PropTypes.node.isRequired,
  gutter: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

const SquareHeroBannerLayout = {};

SquareHeroBannerLayout.Root = Root;
SquareHeroBannerLayout.Background = Background;
SquareHeroBannerLayout.Content = Content;

export default SquareHeroBannerLayout;
