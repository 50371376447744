import PropTypes from 'prop-types';
import ProcuredOffer from '@experiences-ui/shared/propTypes/ProcuredOffer';
import Destination from './Destination';
import Occupants from './Occupants';
import Total from './Total';

const PromotedOrigin = {
  origin: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
  }),
  price: PropTypes.shape(Total),
};

const Property = {
  id: PropTypes.string,
  name: PropTypes.string,
  destination: PropTypes.shape(Destination),
  tagline: PropTypes.string,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
};

const FeaturedProperty = {
  property: PropTypes.shape(Property),
  pillText: PropTypes.string,
  tagline: PropTypes.string,
  nights: PropTypes.number,
  travellers: PropTypes.shape(Occupants),
  origins: PropTypes.arrayOf(PropTypes.string),
  cheapestOrigin: PropTypes.shape(PromotedOrigin),
};

const DateTime = {
  date: PropTypes.string,
  time: PropTypes.string,
};

const DealBanner = {
  headline: PropTypes.string,
  tagline: PropTypes.string,
  termsConditions: PropTypes.string,
};

const DestinationPromoText = {
  termsConditions: PropTypes.string,
  text: PropTypes.string,
};

const CampaignLandingPage = {
  headline: PropTypes.string,
  tagline: PropTypes.string,
  introCopy: PropTypes.string,
  tileHeadline: PropTypes.string,
  tileTermsConditions: PropTypes.string,
  destinationHeadline: PropTypes.string,
  destinations: PropTypes.arrayOf(PropTypes.string),
  longFormTermsConditions: PropTypes.string,
};

const CrossSellBanner = {
  id: PropTypes.string,
  type: PropTypes.string,
};

const Tile = PropTypes.oneOfType([
  PropTypes.shape(CrossSellBanner),
  PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    ...FeaturedProperty,
  }),
  PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    property: PropTypes.shape(Property),
    ...ProcuredOffer,
  }),
]);

const Campaign = {
  id: PropTypes.string,
  name: PropTypes.string,
  start: PropTypes.oneOfType([PropTypes.string, PropTypes.shape(DateTime)]),
  end: PropTypes.oneOfType([PropTypes.string, PropTypes.shape(DateTime)]),
  order: PropTypes.number,
  slug: PropTypes.string,
  image: PropTypes.object,
  activeDestinations: PropTypes.arrayOf(PropTypes.string),
  dealBanner: PropTypes.shape(DealBanner),
  campaignLandingPage: PropTypes.shape(CampaignLandingPage),
  destinationPromoText: PropTypes.shape(DestinationPromoText),
};

export { Tile, PromotedOrigin, Campaign as default };
